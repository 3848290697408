// i18next-extract-mark-ns-start privacy-policy

import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const PrivacyPolicy: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Privacy Policy');
  return (
    <div className="py-24 md:py-32">
      <SEO title={title} />
      <div className="container px-6 py-7 md:py-14 relative z-10 min-h-screen flex justify-center">
        <article className="prose prose-sm sm:prose xl:prose-lg max-w-full">
          <Trans parent="p">
            microapps S.L. (“microapps”) values your privacy and desires to ensure your experience
            with microapps and its subsidiaries and related companies (collectively “microapps”,
            “we,” “our” or “us”) and all our websites and all our related subdomains (the “Sites”)
            and all our products, mobile applications, software, blogs, forums, channels and sharing
            and quizzing services (collectively with the Sites, the “Services”) are as satisfying
            and safe as possible. In that regard, we have established for the Sites and/or Services
            the following privacy policy to explain and describe what personal information microapps
            collects on our Sites and/or Services, how microapps uses it and your options regarding
            information microapps may collect about you. By accepting this Privacy Policy, you
            expressly consent to the information handling practices described in this Policy. This
            Privacy Policy is a part of and incorporated into microapps’s Terms of Use and End user
            License Agreements associated with its Sites and/or Services. In addition, by accepting
            this Privacy Policy you expressly represent: (a) that you are over the age of 18 and/or
            not considered a minor in the jurisdiction for which you reside; or (b) that you are a
            parent or guardian of a minor child and you who have expressly consented to handling of
            the minor’s personal information as described in this Policy; or (c) that you are a
            school, district and/or teacher which has contracted with microapps to collect Personal
            Information of your students. Your continued use of our Sites and/or Services or any
            other content, products or other services covered by this Policy constitutes your
            acceptance and agreement to this Policy and any updates.
          </Trans>
          <Trans parent="h2">1. Information We Collect</Trans>
          <Trans parent="p">
            In describing our collection, use, and sharing of information in this Policy, we refer
            to our collection and use of “Personal Information.” “Personal Information,” as used in
            this Policy, is personally identifiable information, which is information that directly
            identifies an individual, such as first and last name, mailing address, email address,
            billing information, IP addresses, demographics, passwords or other online contact
            information, or telephone number. We collect Personal Information, as well as
            non-personally identifiable information on microapps Sites and/or Services.
          </Trans>
          <Trans parent="p">
            We collect Personal Information in two ways. First, we collect information you provide
            to us in optional, voluntary submissions, such as in registration forms, user profiles,
            promotional signups, sweepstakes/contest entries, requests for customer service,
            software and mobile applications downloads and curriculum vitae submissions. Second,
            some Personal Information may be collected automatically. This is described below in
            “Passive Data Collection.”
          </Trans>
          <Trans parent="p">
            We may also obtain information, including Personal Information, from third party
            sources. If we directly combine information from third parties with Personal Information
            that we have collected, we will treat the combined information as Personal Information
            and handle it in accordance with this Policy. In addition, please note that we may
            solicit third parties to collect Personal Information on our behalf and in such cases we
            instruct those third parties to comply with this Policy and all applicable data
            protection laws.
          </Trans>
          <Trans parent="p">
            Please be aware that we are prohibited from conditioning a child’s participation in any
            activity on that child disclosing more personal information than is reasonably necessary
            to participate in that activity. You are under no obligation to provide, and can decline
            to provide, any requested information at any time. However, if you decline to provide
            personal information that is reasonably necessary to participate in certain activities,
            you may not be able to participate in those activities on certain Sites and/or Services.
          </Trans>
          <Trans parent="h3">Passive Data Collection</Trans>
          <Trans parent="p">
            We may receive passively-collected information through a variety of methods including
            but not limited to
          </Trans>
          <h4>COOKIES</h4>
          <Trans parent="p">
            We may use a variety of methods, including “cookies” to collect information.
          </Trans>
          <h4>WHAT IS A COOKIE?</h4>
          <Trans parent="p">
            Cookies are text files containing small amounts of information which are downloaded to
            your device when you visit a website. Cookies are then sent back to the originating
            website on each subsequent visit, or to another website that recognizes that cookie.
            Cookies are useful because they allow a website to recognize a user’s device. You can
            find more information about cookies at:{' '}
            <a href="http://www.allaboutcookies.org/" target="_blank" rel="nofollow noreferrer">
              www.allaboutcookies.org
            </a>{' '}
            and{' '}
            <a
              href="http://www.youronlinechoices.com/uk/"
              target="_blank"
              rel="nofollow noreferrer">
              http://www.youronlinechoices.com/uk/.
            </a>
          </Trans>
          <Trans parent="p">
            For a video about cookies visit{' '}
            <a
              href="http://www.google.co.uk/goodtoknow/data-on-the-web/cookies"
              target="_blank"
              rel="nofollow noreferrer">
              www.google.co.uk/goodtoknow/data-on-the-web/cookies
            </a>
          </Trans>
          <Trans parent="p">
            Cookies do lots of different jobs, like letting you navigate between pages efficiently,
            remembering your preferences, and generally improve the user experience. They can also
            help to ensure that advertisements you see online are more relevant to you and your
            interests.
          </Trans>
          <Trans parent="p">
            The cookies used on our Sites and/or Services have been categorized based on the
            categories found in the ICC UK Cookie guide. A list of all the cookies used on our Sites
            and/or Services by category is set out below.
          </Trans>
          <h4>PERFORMANCE COOKIES</h4>
          <Trans parent="p">
            microapps collects cookies on our Sites and/or Services to capture information about
            page visits (e.g. “performance cookies.”) This information is anonymous and microapps
            uses this information only internally – to deliver the most effective content to our
            visitors. Information from the cookie is used to gauge page popularity, analyze traffic
            patterns on our Sites and/or Services and guide development of other improvements to our
            Sites and/or Services.
          </Trans>
          <h4>TARGETING, PERFORMANCE, AND FUNCTIONALITY COOKIES</h4>
          <Trans parent="p">
            In our email programs, microapps employs some tracking methods (e.g. “targeting,
            performance, and functionality cookies”). We track “opens” via a tracking pixel in the
            email- meaning we track who opens our e-mail messages and when you open our e-mail
            messages; and we track “clicks” via encoded URLs-meaning we track whether you click on
            the links contained in our e-mail messages. This information is used internally only to
            help us deliver relevant messaging, and is not shared with any third parties.
          </Trans>
          <h4>FUNCTIONALITY AND NECESSARY COOKIES</h4>
          <Trans parent="p">
            microapps does not require that you accept cookies and/or you may withdraw your consent
            to our use of cookies at any time by adjusting your browser’s privacy settings, however,
            some functionality on our Sites (e.g. “functionality cookies”), our product or service
            check-out process, and Services may be disabled if you decline to accept cookies ( e.g.
            “necessary cookies”).
          </Trans>
          <h4>OPTING OUT OF COOKIES</h4>
          <Trans parent="p">
            You can set your browser to notify you when you receive a cookie, giving you the chance
            to decide whether or not to accept it. You may also change your cookie settings through
            preference options in our Sites and/or Services, where applicable. We will indicate that
            upon selecting your preferences, that we will use a cookie to remember your preferences.
          </Trans>
          <Trans parent="p">
            microapps never gives away information about our users. If you choose to provide
            Personal Information about yourself, microapps will use this information only to
            communicate directly with you. microapps will not sell, rent or otherwise disclose that
            information to third parties unless such disclosure is necessary for the purposes set
            forth in this Policy, by law or a policy or notice contained or associated with a
            specific Site(s) and/or Service(s).
          </Trans>
          <h4>LOCATION-BASED SERVICES</h4>
          <Trans parent="p">
            We may collect, use, and share (with microapps partners, service providers and
            licensees) precise location data, including the real-time location of your mobile or
            fixed location device.
          </Trans>
          <h4>ANALYTICS TOOLS</h4>
          <Trans parent="p">
            We use analytics tools and other third party technologies, such as Google Analytics and
            DoubleClick Cookies, to collect non-personal information in the form of various usage
            and user metrics when you use our online Sites and/or Services. These tools and
            technologies collect and analyze certain types of information, including cookies, IP
            addresses, device and software identifiers, referring and exit URLs, onsite behavior and
            usage information, feature use metrics and statistics, usage and purchase history, MAC
            Address, mobile unique device ID, and other similar information.
          </Trans>
          <Trans parent="p">
            The third party analytics companies who collect information on our Sites and/or Services
            and other online products and/or services may combine the information collected with
            other information they have independently collected from other websites and/or other
            online or mobile products and services relating to your activities across their network
            of websites as well as online and/or mobile products and services. Many of these
            companies collect and use information under their own privacy policies.
          </Trans>
          <Trans parent="p">
            In addition to our use of technologies as described herein, we may permit certain third
            party companies to help us tailor advertising that we think may be of interest to you
            based on your use of microapps Sites and/or Services and to otherwise collect and use
            data about your use of microapps Sites and/or Services. For more information about this
            practice, please see the “Third Party Advertising Technologies” section below.
          </Trans>
          <Trans parent="p">
            You may opt out of the DoubleClick cookie by visiting the{' '}
            <a
              href="http://www.google.com/policies/technologies/ads/"
              target="_blank"
              rel="nofollow noreferrer">
              Google advertising opt-out page
            </a>{' '}
            or you may opt out of Google Analytics by visiting the{' '}
            <a
              href="http://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="nofollow noreferrer">
              Google Analytics opt-out page
            </a>
            .
          </Trans>
          <Trans parent="p">
            Google has additional information available about their{' '}
            <a
              href="http://adwords.google.com/support/aw/bin/answer.py?hl=en&amp;answer=100746"
              target="_blank"
              rel="nofollow noreferrer">
              Remarketing Privacy Guidelines
            </a>
            ,{' '}
            <a
              href="http://adwords.google.com/support/aw/bin/answer.py?hl=en&amp;answer=143465"
              target="_blank"
              rel="nofollow noreferrer">
              Policies
            </a>
            , and{' '}
            <a
              href="http://adwords.google.com/support/aw/bin/answer.py?hl=en&amp;answer=178363"
              target="_blank"
              rel="nofollow noreferrer">
              Restrictions
            </a>
            .
          </Trans>
          <Trans parent="h2">2. How We Use Information</Trans>
          <Trans parent="p">
            Generally, we use Personal Information to establish and enhance our relationship with
            our users through four main categories: (1) Communication; (2) Optimization; (3)
            Advertising; and (4) Sharing Features. Below, we provide some specific examples of how
            we use the Personal Information and other information we collect.
          </Trans>
          <Trans parent="h3">Communication and Responding to Requests</Trans>
          <Trans parent="p">
            We use your information, including Personal Information to provide you with customer
            support, process transactions, respond to users’ requests, send newsletters and updates,
            send special offers and advertisements, seek your opinions and feedback, and connect
            users to microapps Sites and/or Services, and to products and services of our partners
            and licensees.
          </Trans>
          <Trans parent="h3">Optimization</Trans>
          <Trans parent="p">
            We use user information, including Personal Information, behavioral metrics, and other
            non-personally identifiable information to operate, provide, improve, and maintain our
            Sites and Services, to develop new products and services, to prevent abuse and fraud, to
            personalize and display advertisements and other content for you, and for other
            administrative and internal business purposes.
          </Trans>
          <Trans parent="h3">Advertising</Trans>
          <Trans parent="p">
            We use user information, including Personal Information, behavioral metrics,
            geo-location data, demographic data and marketing preferences to personalize and display
            advertisements and other content for you.
          </Trans>
          <Trans parent="h3">Sharing Features</Trans>
          <Trans parent="p">
            We use your information, including Personal Information and any other information you
            submit directly or through passive means to provide you with various ways to connect and
            share content (e.g., web content , user generated videos, text, drawings, and screen and
            lecture captures, and quizzing features, media, tools, services and other products) with
            third parties. For example, we may provide you with the opportunity to email a link to
            certain content to another person. To use this feature, we may require you to provide
            certain Personal Information including your email address and the email address of each
            person to whom you send the link.
          </Trans>
          <Trans parent="p">
            We also may allow you to share links to content with users of various third party
            services (e.g., social networking, search and sharing services). When sharing links
            using one of these third party services, if you are not already logged in to the third
            party service, you will need to supply login credentials for it. If you are not a
            registered user of the third party service, you will need to sign up for it. You are
            supplying the registration information or login credentials for the third party service
            directly to that third party, and not to us. By using any of these third party services
            to share links to content, you permit us to access, use and disclose any information
            relating to your account on each such third party service (such as your user name and
            profile information) that is available to us through the third party service, including
            through its application programming interface (API) pursuant to this Policy.
          </Trans>
          <Trans parent="h2">3. When We Disclose Personal Information</Trans>
          <Trans parent="p">
            We do not share Personal Information with third parties except in the limited
            circumstances described in this Policy.
          </Trans>
          <Trans parent="h3">Service Providers</Trans>
          <Trans parent="p">
            We may provide Personal Information to third parties for their use in performing
            internal business functions (e.g., payment processing, customer service, maintenance,
            security, data analysis, email transmission, beta testing, or data hosting) on our
            behalf. Additionally, some third party service providers may collect information,
            including Personal Information, on our behalf. Whenever possible, we require such
            third-parties to only collect and use Personal Information in accordance with this
            Policy, to maintain the confidentiality, security, and integrity of such Personal
            Information, and for no purpose other than providing us with our requested services.
          </Trans>
          <Trans parent="h3">Partners</Trans>
          <Trans parent="p">
            When you visit or use a part of any website, mobile application, or other consumer
            product that is co-branded or that is a part of a joint promotion involving microapps
            and one or more third parties (each a “Co-Branded Party”), you are providing and
            consenting to provide and are transmitting your Personal Information and other
            information provided to, or collected, or obtained by microapps and any Co-Branded
            Parties involved, to microapps and each of the Co-Branded Parties without any other
            notice or further consent. Please note that we do not control any Co-Branded Parties’
            use or treatment of information you provide to them and you should consult each of their
            privacy policies or any separate Co-Branded privacy policy posted on or linked from the
            Co-Branded site to determine your rights with respect to their use and treatment of your
            information. You will need to communicate with us and each Co-Branded Party directly, as
            applicable, if you decide you no longer wish to receive communications or if you wish to
            change any information you have supplied.
          </Trans>
          <Trans parent="h3">Legal Requirements</Trans>
          <Trans parent="p">
            We may disclose Personal Information if we have a good-faith belief that doing so is
            required by a subpoena, warrant, or other judicial or administrative order or otherwise
            required by law. Additionally, we may disclose Personal Information where we, in good
            faith, deem it appropriate or necessary to prevent violation of our Terms of Use or End
            User License Agreements, or our other agreements; take precautions against liability;
            protect our rights, property, or safety, or those of a partner, any individual or the
            general public; maintain and protect the security and integrity of our Sites and/or
            Services or infrastructure; protect ourselves and our Sites and/or Services from
            fraudulent, abusive, or unlawful uses; investigate and defend ourselves against third
            party claims or allegations; or assist government regulatory agencies.
          </Trans>
          <Trans parent="h3">Change of Ownership</Trans>
          <Trans parent="p">
            &gt;Information about our users, including Personal Information, may be disclosed as
            part of any bankruptcy, merger, sale, transfer of company assets, acquisition, or
            similar transaction. In the event of such a transaction in which your Personal
            Information would be transferred to a third party, we will make reasonable efforts to
            notify you. For example, we will post a notice of the transfer of Personal Information
            on our Sites and/or Services and, if we have your email address, we will send notice of
            the transfer of Personal Information to that email address.
          </Trans>
          <Trans parent="h3">Interactive Features</Trans>
          <Trans parent="p">
            If you use a forum, blog, channel, bulletin board, chat room, user commenting features,
            or other interactive sharing or social features that may be offered through any of our
            Sites and/or Services, you should be aware that any Personal Information you submit,
            display, or publish there is considered publicly available and can be read, collected,
            used, and disclosed by other users of those features, by us, and other third parties
            without restriction, except to the extent limited access features are available, if any.
            To request removal of your personal information from our blog, sharing service or
            community forum, for example, contact us at the information provided in this Policy. In
            some cases, we may not be able to remove your Personal Information, in which case we
            will let you know if we are unable to do so and why.
          </Trans>
          <Trans parent="h2">4. Third Party Advertising Technologies</Trans>
          <Trans parent="p">
            In addition to using cookies and related technologies as described above, we also may
            permit certain third party companies to help us tailor advertising that we think may be
            of interest to users and to collect and use other data about user activities on our
            Sites and/or Services (e.g., to allow them to tailor ads on third party services). These
            companies may deliver ads that might also place cookies and otherwise track user
            behavior. These companies may use information about user behavior in order to provide
            customized advertisements across various services and products. In the course of
            providing these services, products or placing advertisements, these third party
            companies may place or recognize a unique cookie on your computer, and may record
            information to these cookies based upon your activities on any of our Sites and/or
            Services and on third party websites. Each of these companies uses this cookie
            information according to their own privacy and security policies. If you wish to not
            have this information used for the purpose of serving you targeted ads, you may opt-out
            as indicated in this Policy. Please note this does not opt you out of being delivered
            advertising. You will continue to receive generic ads.
          </Trans>
          <Trans parent="h2">5. Children Under 13</Trans>
          <Trans parent="p">
            We do not knowingly seek or collect Personal Information from users under the age of 13,
            except to the extent that microapps may collect limited personal information from
            students (but not their email addresses),{' '}
            <strong>
              but only where that student’s school, district, and/or teacher has contracted with
              microapps to collect the personal information from students for the use and benefit of
              the learning environment.
            </strong>{' '}
            Children under 13 years old are not allowed to create an account without a parent’s or
            guardian’s express consent. We recommend that minors over the age of 13 ask their
            parents for permission before sending any information about themselves to anyone over
            the Internet.
          </Trans>
          <Trans parent="p">
            We require schools, districts, and/or teachers to obtain parental or guardian consent
            from a students’ parent or guardian before collecting any such student Personal
            Information. If you are a student, please do not send any Personal Information about
            yourself to us, other than what we request from you when you sign up for any of our
            Sites and/or Services. In the event that we learn that we have collected personal
            information from a student without parental consent being obtained by his or her school,
            district, and/or teacher, or if we learn a student has provided us Personal Information
            beyond what we request when he or she signs up for any of our Sites and/or Services, we
            will delete that information as quickly as possible. If you believe that a student may
            have provided us Personal Information beyond what is requested when signing up for any
            of our Sites and/or Services, or that a student’s school, district, or teacher has not
            obtained parental consent, please contact us at{' '}
            <a href="mailto:hi@microapps.com">hi@microapps.com</a>.
          </Trans>
          <Trans parent="p">
            &gt;&gt;Notwithstanding anything to the contrary contained in this Policy, if you are a
            student as described above, and your parent or guardian signed up for our Sites and/or
            Service with a password or access ID provided by your teacher, you understand that your
            parent or guardian may be able to view all information within or associated with your
            account, including, without limitation, messages between you and your teacher and grades
            you received on your assignment.
          </Trans>
          <Trans parent="p">
            Users under 13 years of age who are not students as described above, your continued use
            of the Site and/or Service will be considered by microapps as confirmation that your
            parent or guardian has expressly consented to the handling of your personal information
            as described in this Policy as further indicated in the opening paragraph of this
            Policy.
          </Trans>
          <Trans parent="h2">6. Your Choices</Trans>
          <Trans parent="h3">Submitting Personal Information</Trans>
          <Trans parent="p">
            If you do not want to submit Personal Information that is necessary to provide or use
            our Sites and/or Services or to fulfill your request, you will not be able to access
            and/or participate in certain microapps Sites and/or Services. For example, you will not
            be able to register for an account with us or participate in certain contests or
            promotions, or access and/or use certain microapps product features or online services.
            In addition, your refusal to submit Personal Information may limit our ability to
            respond to your customer support inquiry in a timely fashion.
          </Trans>
          <Trans parent="h3">Editing Personal Information</Trans>
          <Trans parent="p">
            You may, at any time, update, correct, or delete certain Personal Information that you
            have provided to us by updating your preferences on the Account Settings page or by
            contacting us at the mailing or email address provided set forth in this Policy.
          </Trans>
          <Trans parent="h3">Marketing Choices</Trans>
          <Trans parent="p">
            If you want to remove yourself from individual communications, including marketing
            promotions, advertisements, updates and email newsletters, simply click the unsubscribe
            link in the communication or access your Account Settings page when logged in. You also
            acknowledge and agree that there may be instances where we may elect to notify you about
            administrative notices regarding your account with us, such as to inform you about
            changes to our terms, conditions, and policies, that your account may be expiring, or to
            provide you with other relevant administrative information about your account. Because
            this information is important to your interaction with us, you may not opt out of
            receiving these communications.
          </Trans>
          <Trans parent="h3">Account Deletion</Trans>
          <Trans parent="p">
            If you submit Personal Information, you may delete your account with us at any time. If
            you delete your account, your Personal Information and any and all other account related
            information including, but not limited to, user profile data, purchasing history,
            sharing data and any other data, credits, or content specifically associated with your
            account are also permanently deleted. Deletion of your data may take up to 30 days from
            accessible systems and up to 90 days from backup systems. If you choose to have an
            account with us in the future, you will have to sign up for a new account as none of the
            information you previously provided or saved within your account will have been saved.
          </Trans>
          <Trans parent="h2">7. Security</Trans>
          <Trans parent="p">
            We take reasonable measures, including the implementation and maintenance of physical,
            electronic, and managerial procedures, in an effort to assure the security, integrity,
            and accuracy of all Personal Information that we collect. Our efforts include procedures
            designed to avoid unauthorized access, alteration, misuse or disclosure of Personal
            Information.
          </Trans>
          <Trans parent="p">
            If we learn of a security systems breach, we may attempt to notify you electronically so
            that you can take appropriate protective steps. By using the microapps Sites and/or
            Services covered by this Policy and providing Personal Information to us, you agree that
            we can communicate with you electronically regarding security, privacy and
            administrative issues relating to your account information and usage.
          </Trans>
          <Trans parent="h2">8. Data Integrity and Data Retention</Trans>
          <Trans parent="p">
            We collect and use Personal Information in accordance with this Policy and only for the
            purposes stated in this Policy and/or at the time we request such Personal Information
            from our users, or for such purposes to which a user may otherwise consent. We do not
            use or share our users’ Personal Information in a way that is incompatible with the
            purposes for which such Personal Information was requested and authorized for use by us.
            We retain Personal Information for no longer than is commercially useful to carry out
            our business purposes, or as we believe may be legally required. When Personal
            Information is deleted or updated by you, it may take up to 30 days from accessible
            systems, and up to 90 days from backup systems, for us to delete such Personal
            Information.
          </Trans>
          <Trans parent="h2">9. Access to Personal Information</Trans>
          <Trans parent="p">
            You may view, correct, or delete the Personal Information microapps stores about you.
            Most of your Personal Information may be accessed and corrected by simply logging into
            your account and accessing your profile. If you wish to obtain a report of the Personal
            Information microapps has about you, please send us a written request at the address set
            forth in this Policy.
          </Trans>
          <Trans parent="h2">10. Third Party Login Functionality</Trans>
          <Trans parent="p">
            We may permit users with accounts on certain third party services, including but not
            limited to, social networking, search, sharing, and other services delivered by
            third-parties, to bypass the standard applicable registration process. If you connect
            through an authorized third party service, you will be able to use your third party
            login information to log in to your microapps account or access microapps content, Sites
            and/or Services. We are not responsible for the sharing, posting, commenting, or other
            content and information gathering practices of any third party services that may be
            triggered by using these third party login services. Please review their terms of
            service and privacy policies carefully before using third party login functionality to
            access any microapps Sites and/or Services.
          </Trans>
          <Trans parent="p">
            By using any third party services to log in to your microapps account or access
            microapps Sites and/or Services, you permit us to access, store and use all information
            related to your third party account that may be accessible to us through the third party
            service, including through its application programming interface (API), pursuant to this
            Policy.
          </Trans>
          <Trans parent="h2">11. Third Party Services and Links</Trans>
          <Trans parent="p">
            microapps’s content, including forums contests, products, services, newsletters and
            communications that we send to our users, may contain links to content provided by
            third-parties. For example, our communications may contain links to third party
            merchants ( i.e. Digital Juice) from whom you may purchase products. To purchase
            products from these third party merchants, you may be required to provide Personal
            Information, including your credit card or other payment information.
          </Trans>
          <Trans parent="h2">12. US Privacy Rights</Trans>
          <Trans parent="p">
            In addition to the rights set forth in this Privacy Policy, US Laws permits US residents
            who are individual consumers or users of microapps Sites and/or Services or services to
            request certain information regarding its disclosure of personal information to third
            parties for their direct marketing purposes. To make such a request, please contact us
            via mail or email at the address identified in the “Contacting Us” section.
          </Trans>
          <Trans parent="h2">13. Changes and Updates to This Policy</Trans>
          <Trans parent="p">
            We may update this Policy from time to time. If we do, we will update the “effective
            date” at the top of the Policy. If we make material changes to how we use Personal
            Information, we will attempt to notify you of the change and to give you the opportunity
            to exercise choice as to how your Personal Information is used. We will post the updated
            Policy on the affected Sites and/or Service, and if we have your email address, we will
            send notice of the update to that email address.
          </Trans>
          <Trans parent="p">
            We encourage you to review this Policy periodically to stay informed about our
            collection, use and disclosure of Personal Information. Your continued use of our Sites
            and/or Services or any other content, products or other services covered by this Policy
            constitutes your agreement to this Policy and any updates.
          </Trans>
          <Trans parent="h2">14. International Users &amp; U.S.-EU Switzerland Safe Harbor</Trans>
          <Trans parent="p">
            microapps Sites and/or Services may be hosted in the United States. If you utilize our
            Sites and/or Services from the European Union or Switzerland, or any other region with
            laws governing data collection and use that may differ from U.S. law, please note that
            you are transferring your personal data outside of those jurisdictions to the United
            States. The United States does not have the same data protection laws as the European
            Union, Switzerland and other regions. By providing Personal Information under this
            Policy, you consent to the use of your Personal Information in accordance with this
            Policy and the transfer of your Personal Information to the United States.
          </Trans>
          <Trans parent="p">
            The Personal Information practices set out in this Privacy Policy apply to all microapps
            Sites and/or Services, and customers worldwide. You have a right of access and to
            correct such data and/or Personal Information. To exercise these rights, or for other
            data privacy questions, please contact/send an email to{' '}
            <a href="mailto:hi@microapps.com">hi@microapps.com</a>
          </Trans>
          <Trans parent="p">
            microapps is committed to complying with the U.S.-EU Safe Harbor Framework and the
            U.S.-Swiss Safe Harbor Framework as set forth by the U.S. Department of Commerce
            regarding the collection, use, and retention of personal information from European Union
            member countries and Switzerland. microapps has certified that it is committed to adhere
            to the Safe Harbor Privacy Principles of notice, choice, onward transfer, security, data
            integrity, access, and enforcement. To learn more about the Safe Harbor program, and to
            view microapps’s certification, please visit{' '}
            <a href="http://www.export.gov/safeharbor/" target="_blank" rel="nofollow noreferrer">
              http://www.export.gov/safeharbor/
            </a>
            .
          </Trans>
          <Trans parent="h2">15. Enforcement</Trans>
          <Trans parent="p">
            microapps regularly reviews its compliance with this Privacy Policy. Please feel free to
            direct any questions or concerns regarding this Policy or microapps’s treatment of
            Personal Information by contacting us through this Site at{' '}
            <a href="mailto:hi@microapps.com">hi@microapps.com</a> or by writing to us at Contact
            Address stated on “Contacting Us” section. When microapps receives a formal written
            complaint at this address, it is microapps’s policy to contact the complaining party
            regarding his or her concerns. microapps will cooperate with the appropriate regulatory
            authorities, including local data protection authorities, to resolve any complaints
            regarding the transfer of personal data that cannot be resolved by microapps and an
            individual.
          </Trans>
          <Trans parent="h2">16. No Error Free Performance</Trans>
          <Trans parent="p">
            microapps does not guarantee error-free performance under this Privacy Policy. microapps
            will use reasonable efforts to comply with this Privacy Policy and will take prompt
            corrective action when microapps learns of any failure to comply with our Privacy
            Policy. microapps shall not be liable for any incidental, consequential or punitive
            damages relating to this Privacy Policy. This Privacy Policy shall be governed by the
            laws of the United States, to the extent applicable.
          </Trans>
        </article>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "privacy-policy"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
